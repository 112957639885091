import React from "react"
import { Parallax, Background } from "react-parallax";

const getRandom = (min, max) => {
    return Math.random() * (max - min) + min;
}

const ParallaxBackground = ({children, backgroundClass, circle}) => (
    <>
        <Parallax strength={getRandom(150, 250)}>
            <Background className="custom-bg">
                <div
                    className={backgroundClass}
                    style={{
                        height: 6000,
                        width: 2000,
                    }}
                >
                    <svg height="1000" width="2000">
                        {circle && <circle cx={getRandom(500, 1900)} cy={getRandom(300, 800)} r={getRandom(300, 600)} fill="white" opacity={0.05} />}
                    </svg>
                </div>
            </Background>
            {children}
        </Parallax>

    </>
)

export default ParallaxBackground;

export default {
    menu:
        [{
            name: "Oplossingen",
            url: "/oplossingen",
            subitems: [{
                name: "Consulting",
                url: "/consulting",
                subitems: [
                    {
                        name: "Programma- & Project<wbr>management",
                        url: "/programma-project-management"
                    },{
                        name: "Business & Informatie Analyse",
                        url: "/business-informatie-analyse"
                    },{
                        name: "Kwaliteits<wbr>management",
                        url: "/kwaliteitsmanagement"
                    },{
                        name: "Asset Management",
                        url: "/asset-management"
                    },
                ]
            },{
                name: "Performance Management",
                url: "/performance-management",
                subitems: [
                  {
                    name: "Jedox",
                    url: "/jedox"
                  },
                  {
                    name: "Write! Plan",
                    url: "/write-plan"
                 }
                 ]
            },{
                name: "Business Intelligence",
                url: "/business-intelligence",
                subitems: [
                    {
                        name: "Qlik",
                        url: "/qlik"
                    },
                    {
                        name: "Microsoft Power BI",
                        url: "/power-bi"
                    },
                    {
                        name: "Vizlib",
                        url: "/vizlib"
                    },
                    {
                        name: "Write!",
                        url: "/write"
                    },
                    {
                        name: "TimeXtender",
                        url: "/timextender"
                    },
                    {
                        name: "NodeGraph",
                        url: "/nodegraph"
                    }
                    ]
            },{
                name: "Data Governance",
                url: "/data-governance",
                subitems: [
                    {
                        name: "Indigo",
                        url: "/indigo"
                    }, {
                        name: "Master Data Management",
                        url: "/master-data-management"
                    }, {
                        name: "Informatie & Data Governance",
                        url: "/informatie-data-governance"
                    }, {
                        name: "Data Migratie",
                        url: "/data-migratie"
                    }, {
                        name: "Data Kwaliteit",
                        url: "/data-kwaliteit"
                    }, {
                        name: "AVG / SOX",
                        url: "/avg-sox"
                    }
                ]
            },{
                name: "Rapid Application Development",
                url: "/rapid-application-development",
                subitems: [{
                    name: "Mendix",
                    url: "/mendix"
                },{
                    name: "Betty Blocks",
                    url: "/betty-blocks"
                },{
                    name: "Relatics",
                    url: "/relatics"
                }
                ]
            },{
                name: "Software ontwikkeling",
                url: "/software-ontwikkeling",
                subitems: [{
                    name: "Remote Devops Team",
                    url: "/remote-devops-team"
                },{
                    name: "Java & .NET",
                    url: "/java-dotnet"
                }]
            },{
                name: "SAP",
                url: "/sap",
                subitems: [
                    {
                        name: "SAP R/3 Consulting",
                        url: "/sap-consulting"
                    },
                    {
                        name: "SAP HANA Migratie",
                        url: "/sap-hana-migratie"
                    }
                ]
            }]
        },{
            name: "Made by DDC",
            url: "/made-by-ddc",
            subitems: [
                {
                    name: "Indigo",
                    url: "/ddc-indigo",
                    subitems: []
                },{
                    name: "Internal Control Monitor",
                    url: "/internal-control-monitor",
                    subitems: []
                },{
                    name: "TurnAround Management",
                    url: "/turnaround-management",
                    subitems: []
                },{
                    name: 'Uren Boeken',
                    url: '/uren-boeken',
                    subitems: []
                },{
                    name:'Qlik Data Extractor',
                    url: '/qlik-data-extractor',
                    subitems:[]
                },{
                    name: "SAP HANA Migratie",
                    url: "/ddc-sap-hana-migratie",
                    subitems: []
                }
            ]
        },{
            name: "Klanten",
            url: "/klanten",
            subitems: []
        },{
            name: "Over ons",
            url: "/over-ons",
            subitems: []
        },{
            name: "Nieuws",
            url: "/nieuws",
            subitems: []
        },{
            name: "Contact",
            url: "/contact",
            subitems: []
        }]
}

import {graphql, Link, StaticQuery} from "gatsby"
import React, { Component } from "react"
import logo from "../../images/logo_header.png"
import volgOns from "../../images/follow_us.png"
import { slide as Menu } from "react-burger-menu"
import menuData from "../../data/nl.menu"
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

class Header extends Component {

  constructor(props) {
    super(props)
    this.props = props;
    this.state = {
        hover: null
    }
  }

    setHover(target) {
        this.setState({hover: target})
    }

    delHover() {
        //this.setState({hover: null})
    }

  render() {

      const submenu = this.state.hover ? <div>
          <nav className={"right-0 hide-1176"}>
              <div className={"nav-sub list-style-none bold t-14 align-right m-0 ml-10 mr-70"}>
                  {
                      this.state.hover.subitems.map((item, index) => {
                          return <div key={index} className={""}><Link
                              to={item.url} className={"block pl-15-imp pr-15-imp pt-7 pb-7"} activeClassName="active" dangerouslySetInnerHTML={{__html: item.name}}/>
                              <div className={"dropdown-content"}>
                                  {item.subitems.map((subitem, index) => {
                                      return <Link key={index} className={"block align-left pt-4 pb-4 pl-10 pr-10 white"} to={subitem.url} dangerouslySetInnerHTML={{__html: subitem.name}}/>
                                  })}
                              </div>
                          </div>
                      })
                  }
              </div>
          </nav>
      </div> : '';

    return (
    <StaticQuery
        query={graphql`
                            query Menu {
                              allStrapiMenu {
                                nodes {
                                  locale
                                  structure {
                                    menu {
                                      name
                                      subitems {
                                        name
                                        subitems {
                                          name
                                          url
                                        }
                                        url
                                      }
                                      url
                                    }
                                  }
                                }
                              }
                            }
                          `}
        render={gqlData => {
            const pages = gqlData.allStrapiMenu.nodes.find(node => node.locale === "nl-NL");
            const menu = pages.structure.menu;

            return (
                <>
                    <header className={"wrapper f-container z-300 transition-background fixed " + (this.props.scroll ? "" : "")}>
                        <div className="ml-40 logo-bg">
                            <Link to="/">
                                <img width={259} height={126} className="m-20 h-80 w-auto" src={logo} alt="DDCgroup Logo"/>
                            </Link>
                        </div>
                        <div className={"f-container f-column f-1-0"}>
                            <div className={"very-dark-blue white-text f-container menu"}>
                                <nav className="nav-main mr-60 hide-1176 f-1-0">
                                    <ul className="list-style-none bold t-16 align-right m-0 h-full f-container f-justify-end">
                                        {menu.map((item, index) => {
                                            const hover = this.state.hover ? this.state.hover.name === item.name ? "hover" : "" : "";
                                            return <li key={index} className={"ml-24-imp h-full f-container f-center-content " + hover}><Link
                                                to={item.url} onMouseEnter={() => this.setHover(item)}
                                                onMouseLeave={() => this.delHover()} dangerouslySetInnerHTML={{__html: item.name}}/></li>
                                        })}
                                    </ul>
                                </nav>
                                <div className={"f-container p-5"}>
                                    <img width={100} height={50} className={"f-center mr-10 w-auto h-50 hide-1024"} src={volgOns} alt="Volg ons"/>
                                    <iframe scrolling={'no'} style={{overflow: "hidden"}} title={"linkedin-follow"} className={"f-center"} height={"24px"} width={"120px"} src={"https://www.linkedin.com/pages-extensions/FollowCompany?id=483274&counter=left"}/>
                                </div>
                            </div>
                            <div className={"sub-menu"}>{submenu}</div>
                        </div>
                    </header>
                    <Menu burgerButtonClassName={"burger-icon"} width={350} className="very-dark-blue white-text" right>
                        {
                            menu.map((item, index) => {
                                return <div key={index} className={'t-14 mb-20'}>
                                    <Link className="bold mb-14" to={item.url} activeClassName="active"  dangerouslySetInnerHTML={{__html: item.name}}/>

                                    {item.subitems.map((item, index) => {
                                        return <div key={index} className={'mb-10'}>
                                            <Link className="pl-10" to={item.url} activeClassName="active"  dangerouslySetInnerHTML={{__html: item.name}}/>

                                            {
                                                item.subitems.map((item, index) => {
                                                    return <div key={index} className={''}>
                                                        <Link className="pl-20" to={item.url} activeClassName="active" dangerouslySetInnerHTML={{__html: item.name}}/>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    })}
                                </div>
                            })
                        }
                    </Menu>
                </>
            )}}
    />
    )
  }
}

export default Header

import image1 from '../images/AdobeStock_203384096.jpg'
// import image2 from '../images/AdobeStock_241450546_80.jpg'
import image2 from '../images/AdobeStock_241450546_80.jpg'
import image3 from '../images/AdobeStock_349044023_80.jpg'
import sjef from "../images/sjef_van_eck.jpeg";
import video from '../images/videos/bg.mp4'
// import video from '../images/videos/AdobeStock_418099615_Video_HD_Preview.mp4'

//clients
import rituals from "../images/customers/rituals.png";
import rijkswaterstaat from "../images/customers/rijkswaterstaat.svg";
import philips from "../images/customers/philips.svg";
import adidas from "../images/customers/adidas.svg";
import accordis from "../images/customers/accordis.svg";
import htm from "../images/customers/htm.svg";
import groenhuysen from "../images/customers/groenhuysen.png";

//partners
import qlik from "../images/partners/qlik.svg";
import board from "../images/partners/board.png";
import microsoft from "../images/partners/microsoft.png";
import timextender from "../images/partners/timextender.png";
import jedox from "../images/partners/jedox.png";
import bettyblocks from "../images/partners/bettyblocks.svg";
import vizlib from "../images/partners/vizlib.png";
import sap from "../images/partners/sap.png";
import mendix from "../images/partners/mendix.png";
import nodegraph from "../images/partners/nodegraph.png";
import relatics from "../images/partners/relatics.png";
import write from "../images/partners/write.webp";

export default {
  companyInfo: {
    email: "info@ddcgroup.com",
    phone: "+31 (0)161 747 828",
    office: "Crown Business Center",
    address: "Ericssonstraat 2",
    postcode: "5121 ML Rijen"
  },
  home: {
    meta: {
      title: "Wij kennen de kracht van informatie",
      description: "Met de juiste informatie functioneert uw bedrijf efficienter, en bent u de concurrentie een stap voor."
    },
    jumbotron: {
      title: "Next level insights",
      subtitle: "Achieve more with data.",
      callToAction: "Neem contact op",
      callToActionLink: "/contact",
    },
    blockHeader: {
      text: "Wij kennen de kracht van informatie."
    },
    block1: {
      image: {
        image: image1,
        alt: "Casual working"
      },
      video: video,
      header: "",
      text: "Organisaties presteren beter door effectiever gebruik te maken van beschikbare data. Door het slim inzetten van de juiste tools en oplossingen werken DDC consultants samen met onze klanten om data te transformeren naar overzichtelijke, inzichtelijke en bruikbare informatie.<br><br>Dat noemen wij <span class='italic'>Next Level Insights</span>.<br><br>Met die informatie worden onderbouwde besluiten genomen en organisaties geoptimaliseerd en met betere kwaliteit aangestuurd.<br><br>Dat noemen wij <span class='italic'>Achieve More with Data</span>."
    },
    quote: {
      text: "DDC laat zien dat IT projecten wél direct kunnen bijdragen aan onze efficiency",
      person: {
        name: "Sjef van Eck",
        function: "CFO",
        company: "Van Happen Containers",
        image: {
          image: sjef,
          alt: "Sjef van Eck"
        }
      }
    },
    clients: {
      header: "Wij werken samen met",
      clients: [
        {
          name: "Rituals",
          logo:rituals,
          url:"/rituals"
        },
        {
          name: "Rijkswaterstaat",
          logo:rijkswaterstaat,
          url:"#"
        },
        {
          name: "Philips",
          logo:philips,
          url:"#"
        },
        {
          name: "Adidas",
          logo:adidas,
          url:"#"
        },
        {
          name: "Accordis",
          logo:accordis,
          url:"#"
        },
        {
          name: "HTM",
          logo:htm,
          url:"#"
        },
        {
          name: "Groenhuysen",
          logo:groenhuysen,
          url:"#"
        }
      ]
    },
    block2: {
      image: {
        image: image2,
        alt: "Klantoverleg"
      },
      header: "Consulting",
      text: "De ketting is zo sterk als de zwakste schakel: het implementeren van slimme oplossingen vraagt dan ook een brede kennis en diepgaande specialisatie op meerdere vakgebieden: dat mag je van ons verwachten!<br><br> Meer dan 40 consultants werken samen met klanten aan (de integratie van) oplossingen. We zijn trots dat de samenwerking met onze klanten resulteert in hechte en langdurige relaties.<br><br>Dat noemen wij consulting met <span class='italic'>Next Level Insights</span>."
    },
    block3: {
      image: {
        image: image3,
        alt: "Datashow laptop"
      },
      header: "Data Solutions",
      text: "Door samen te werken met leading software leveranciers worden data oplossingen geïmplementeerd die direct resultaat leveren en eenvoudig zijn in gebruik. DDC levert data oplossingen op het gebied van business analytics &amp; rapportage, budgettering &amp; forecasting, data management &amp; data kwaliteit en software ontwikkeling. Oplossingen die je altijd verder helpen om betere informatie te halen uit data.<br><br>Dat noemen wij data met <span class='italic'>Next Level Insights</span>."
    },
    partners: {
      header: "Wij zijn partner van",
      partners: [
        {
          name: "Qlik",
          logo: qlik,
          url: "#"
        },{
          name: "performance management",
          logo: board,
          url: "#"
        },{
          name: "Power-bi",
          logo: microsoft,
          url: "#"
        },{
          name: "TimeXtender",
          logo: timextender,
          url: "#"
        },{
          name: "Jedox",
          logo: jedox,
          url: "#"
        },{
          name: "Betty Blocks",
          logo: bettyblocks,
          url: "#"
        },{
          name: "Vizlib",
          logo: vizlib,
          url: "#"
        },{
          name: "SAP",
          logo: sap,
          url: "#"
        },{
          name: "Mendix",
          logo: mendix,
          url: "#"
        },{
          name: "NodeGraph",
          logo: nodegraph,
          url: "#"
        },{
          name: "Relatics",
          logo: relatics,
          url: "#"
        },{
          name: "Write",
          logo: write,
          url: "#"
        },
      ]
    },
    slogan: "Wij kennen de kracht van informatie",
    explanation: {
      title:
        "Met de juiste informatie functioneert uw bedrijf efficienter, en bent u de concurrentie een stap voor.",
      text:
        "Informatie is een onmisbaar ingrediënt voor een gezonde bedrijfsvoering.  Bij DDC onderkennen wij de kracht van informatie. We helpen u om te bepalen welke informatie voor uw organisatie belangrijk is en bieden vervolgens oplossingen om deze informatie overzichtelijk beschikbaar te stellen. Een team van meer dan 30 professionals staat voor u klaar, om uw informatiesystemen te optimaliseren. Wij maken uw organisatie slimmer, effectiever en bieden nieuwe mogelijkheden.",
    },

    news: {
      title: "Laatste nieuws."
    }

  },
  contactBlock: {
    header: "Ook toe aan <span class='italic'>Next Level Insights</span>?",
    subheader: "Neem contact met ons op.",
    form: {
      name: "Naam:",
      company: "Organisatie:",
      emailAddress: "e-mailadres:",
      message: "Uw bericht:",
      phone: "Telefoonnummer:",
      submit: "Verzend",
      errors: {
        notEmpty: "Mag niet leeg zijn.",
        invalidEmail: "Voer een correct emailadres in."
      },
      thanks: "Dank voor uw interesse in DDC"
    }
  },
  pageNotFound: {
    title: "Oeps!",
    subtitle: "We kunnen de pagina die u zoekt helaas niet vinden.",
    code: "Error code: 404",
    helpfulLinksTitle: "Misschien heeft u iets aan één van deze pagina's:",
    helpfulLinks: [
      { title: "Home", ref: "/" },
      { title: "Oplossingen", ref: "/oplossingen" },
      { title: "Over DDCgroup", ref: "/over-ddcgroup" },
    ]
  }
}
